th.fc-col-header-cell.fc-day.fc-day {
  background-color: #2e3c50;
}

div.fc-scroller {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  overflow: hidden !important;
}

.fc-toolbar-title {
  padding-left: 6px;
  padding-right: 6px;
}

:where(.css-dev-only-do-not-override-1me4733) a.fc-col-header-cell-cushion {
  color: aliceblue;
}

.fc .fc-daygrid-day-top {
  padding-right: 15px;
}

table.fc-scrollgrid {
  border-radius: 6px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #1677ff;
  border-radius: 7px;

}

.element::-webkit-scrollbar-thumb {
  background-color: #adb1b6;
}

.element::-webkit-scrollbar {
  width: 7px;

}

.Panel p {
  color: #2e3c50;
}

.ant-collapse-header-text {
  color: #1677ff;
  font-weight: 600;
}

.fc-hidden-label {
  display: none;
  width: 0;
}

@media print {
  .print-area {
    width: 100%;
    margin: 20px
  }

  .print-area-day {
    margin: 40px
  }
}

.fc-view-harness {
  height: 600px !important;
}

.lane-height {
  height: 60px !important;
}